import { useTranslation } from "react-i18next";
import { animated, useTransition, easings } from '@react-spring/web'
import './styles/Popup.css';
import { useState } from "react";


function Env3D(props) {
    const { t } = useTranslation()
    const [selected, setSelected] = useState(null);
    const envTransition = useTransition(props.envVisible, {
        from: { opacity: 0, scale: 1.1 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 1.1 },
        delay: 0,
        config: {
            duration: 250,
            easing: easings.easeInOutQuart,
        }
    })
    const close = (close) => {
        //console.log(close);
        props.setEnvVisible(!close);
        setSelected(props.currentBGid);
        //props.setCurrentBGid(null);

    };
    const open3Denv = () => {
        //console.log(props.currentBGid);
        if (selected !== null && props.currentBGid !== selected) {
            props.setCurrentBGid(selected)
            close(true);
            //console.log(props.bg);
            props.iframe.contentWindow.postMessage({
                integration: { mode: 'photo', name: bgById(selected) },
            });
        }

    }

    const bgById = (id) => {
        var tmp = null;
        {
            props.data.background.map((bg) => {
                if (bg.id === id) {
                    tmp = bg.code;
                }
            })
        }
        return tmp;
    }


    return envTransition(

        (style, item) => item && <animated.div style={{ opacity: style.opacity }} className="popup noprint env3d">
            <div onClick={() => close(true)} className="popup-bg"></div>
            <animated.div style={{ scale: style.scale }} className="popup-prompt">
                <div onClick={() => close(true)} className="popup-quit"></div>
                <div className="popup-prompt-top">
                    <h2>{t("seeEnv3d")}</h2>
                    <p>{t("env3dDesc")}</p>
                </div>
                <span className="separator"></span>
                <div className="popup-prompt-parent">
                    {props.data.background.map((bg) => {
                        return <Button3D model={props.model} close={close} selected={selected} setSelected={setSelected} iframe={props.iframe} key={bg.id} num={bg.id} setCurrentBGid={props.setCurrentBGid} title={t("customWord", { 'en': bg.name_en, "fr": bg.name_fr })} bg={bg.code} />
                    })}
                </div>
                <div className="popup-prompt-bottom">
                    <button onClick={() => close(true)} className="cancel">{t("cancel")}</button>
                    <button onClick={() => open3Denv()} className="accept">{t("accept")}</button>
                </div>
            </animated.div>
        </animated.div>
    );
}

function Button3D(props) {
    const { t } = useTranslation();
    //console.log
    const active = props.selected === props.num ? "active" : "";
    const disabled = props.model.build.data?.background.includes(props.num) ? '' : "disabled";

    const setScene = (number) => {
        if (disabled == '') {
            props.setSelected(number)
        }

    }

    return (
        <div style={{ display: disabled === 'disabled' ? "none" : "block" }} className={active + ' ' + disabled} onClick={() => setScene(props.num)}>
            <div className="popup-prompt-thumbnail" style={{ backgroundImage: "url(../env3d/" + props.bg + ".jpg" }}></div>
            <h4>{disabled === 'disabled' ? t('unavailable') : props.title}</h4>
        </div>
    )
}

export default Env3D;