import { Font } from '@react-pdf/renderer'
import FontDIN from '../../src/fonts/DINPro-Medium.ttf'
import FontMONO from '../../src/fonts/ShareTechMono-Regular.ttf'


Font.register({ family: 'DINPro', src: FontDIN  });
Font.register({ family: 'MONO', src: FontMONO  });

Font.registerHyphenationCallback(word => {
    return [word];
  });

export const PrintStyle = ({
    fontNormal:{
        fontFamily:'DINPro'
    },
    fontMono:{
        fontFamily:'MONO'
    },
    doc:{
        height:'100vh',
        position:'absolute'
    },
    img:{
        width:'70vh',
        margin:'0 auto'
    },
    topP:{fontSize:'9.5pt'},
    topTxt:{
        marginLeft:'50px',
        position:'absolute',
        bottom:'10px'
    },

    bottom:{
        borderTop:'1px solid black',
        display:'flex',
        flexDirection: 'row',
        width:'100%',
        position:'absolute',
        bottom:'-30vh',
        height:'30vh'
    },
    /* First part */
    logo:{
        height:'100px',
        width:'auto',
        marginRight:'20px'
        
    },
    first:{
        width:'36%',
        boxSizing:'border-box',
        display:'block',
        paddingLeft:'50px',
        flexDirection: 'row',
        alignItems:'center',
    },
    /* Second part */
    second:{
        borderLeft:'1px solid black',
        borderRight:'1px solid black',
        width:'28%',
        boxSizing:'border-box',
        display:'block',
        flexDirection:'row',
        padding:'20 20 0 20',
        justifyContent:'space-between'
    },

    mtl:{
        width:'120',
        backgroundColor:'white',
        marginBottom:'6'
    },
    roundColor:{
        width:'12',
        height:'12',
        border:'1',
        borderRadius:'50%',
        boxSizing:'border-box',
    },
    model3D:{        
        height:'13',
        color:"#000000",
        right:'0',
        display:"flex"
    },
    /* Third part */
    third:{
        width:'36%',
        boxSizing:'border-box',
        display:'flex',
        flexDirection:'row',
        paddingTop:'20px',
        paddingLeft:'20px',
        paddingRight:'50px',
        justifyContent:'space-between'
    },
    

})