export const countries = [
    {
        en: "Canada",
        fr: "Canada",
        code: "can",
        regions: [
            {
                en: "Alberta",
                fr: "Alberta"
            },
            {
                en: "British Columbia",
                fr: "Colombie-Britannique"
            },
            {
                en: "Manitoba",
                fr: "Manitoba"
            },
            {
                en: "New Brunswick",
                fr: "Nouveau-Brunswick"
            },
            {
                en: "Newfoundland and Labrador",
                fr: "Terre-Neuve-et-Labrador"
            },
            {
                en: "Nova Scotia",
                fr: "Nouvelle-Écosse"
            },
            {
                en: "Ontario",
                fr: "Ontario"
            },
            {
                en: "Prince Edward Island",
                fr: "Île-du-Prince-Édouard"
            },
            {
                en: "Quebec",
                fr: "Québec"
            },
            {
                en: "Saskatchewan",
                fr: "Saskatchewan"
            },
            {
                en: "Northwest Territories",
                fr: "Territoires du Nord-Ouest"
            },
            {
                en: "Nunavut",
                fr: "Nunavut"
            },
            {
                en: "Yukon",
                fr: "Yukon"
            }
        ]
    },
    {
        en: "United States",
        fr: "États-Unis",
        code: "usa",
        regions: [
            {
                fr: "Alabama",
                en: "Alabama"
            },
            {
                fr: "Alaska",
                en: "Alaska"
            },
            {
                fr: "Arizona",
                en: "Arizona"
            },
            {
                fr: "Arkansas",
                en: "Arkansas"
            },
            {
                fr: "Californie",
                en: "California"
            },
            {
                fr: "Caroline du Nord",
                en: "North Carolina"
            },
            {
                fr: "Caroline du Sud",
                en: "South Carolina"
            },
            {
                fr: "Colorado",
                en: "Colorado"
            },
            {
                fr: "Connecticut",
                en: "Connecticut"
            },
            {
                fr: "Dakota du Nord",
                en: "North Dakota"
            },
            {
                fr: "Dakota du Sud",
                en: "South Dakota"
            },
            {
                fr: "Delaware",
                en: "Delaware"
            },
            {
                fr: "Floride",
                en: "Florida"
            },
            {
                fr: "Géorgie",
                en: "Georgia"
            },
            {
                fr: "Hawaï",
                en: "Hawaii"
            },
            {
                fr: "Idaho",
                en: "Idaho"
            },
            {
                fr: "Illinois",
                en: "Illinois"
            },
            {
                fr: "Indiana",
                en: "Indiana"
            },
            {
                fr: "Iowa",
                en: "Iowa"
            },
            {
                fr: "Kansas",
                en: "Kansas"
            },
            {
                fr: "Kentucky",
                en: "Kentucky"
            },
            {
                fr: "Louisiane",
                en: "Louisiana"
            },
            {
                fr: "Maine",
                en: "Maine"
            },
            {
                fr: "Maryland",
                en: "Maryland"
            },
            {
                fr: "Massachusetts",
                en: "Massachusetts"
            },
            {
                fr: "Michigan",
                en: "Michigan"
            },
            {
                fr: "Minnesota",
                en: "Minnesota"
            },
            {
                fr: "Mississippi",
                en: "Mississippi"
            },
            {
                fr: "Missouri",
                en: "Missouri"
            },
            {
                fr: "Montana",
                en: "Montana"
            },
            {
                fr: "Nebraska",
                en: "Nebraska"
            },
            {
                fr: "Nevada",
                en: "Nevada"
            },
            {
                fr: "New Hampshire",
                en: "New Hampshire"
            },
            {
                fr: "New Jersey",
                en: "New Jersey"
            },
            {
                fr: "État de New York",
                en: "New York"
            },
            {
                fr: "Nouveau-Mexique",
                en: "New Mexico"
            },
            {
                fr: "Ohio",
                en: "Ohio"
            },
            {
                fr: "Oklahoma",
                en: "Oklahoma"
            },
            {
                fr: "Oregon",
                en: "Oregon"
            },
            {
                fr: "Pennsylvanie",
                en: "Pennsylvania"
            },
            {
                fr: "Rhode Island",
                en: "Rhode Island"
            },
            {
                fr: "Tennessee",
                en: "Tennessee"
            },
            {
                fr: "Texas",
                en: "Texas"
            },
            {
                fr: "Utah",
                en: "Utah"
            },
            {
                fr: "Vermont",
                en: "Vermont"
            },
            {
                fr: "Virginie",
                en: "Virginia"
            },
            {
                fr: "Virginie-Occidentale",
                en: "West Virginia"
            },
            {
                fr: "État de Washington",
                en: "Washington"
            },
            {
                fr: "Wisconsin",
                en: "Wisconsin"
            },
            {
                fr: "Wyoming",
                en: "Wyoming"
            }
        ]
    },{
        en: "Other",
        fr: "Autre",
        code: "other",
        regions: [
            {
                fr: "Autre",
                en: "Other"
            }
        ]
    }
]