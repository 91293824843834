import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import './styles/OnBoarding.css';
import { useTransition, animated, useSpringRef, easings } from '@react-spring/web'
import { FormControl, MenuItem, Select } from '@mui/material';
import LoadingScreen from './LoadingScreen';

var numLoad = 0;

export default function OnBoarding(props) {
    const { t } = useTranslation();
    const [index, setIndex] = useState(0);
    const [product, setProduct] = useState({ build: null, lampshade: null });
    const [isFinished, setFinished] = useState({ val: false, type: "" });
    const [filter, setFilter] = useState("");
    const transRef = useSpringRef()
    const build = props.data.builds.sort((a, b) => Number(String(a.name).replace(/\D/g, '')) > Number(String(b.name).replace(/\D/g, '')) ? 1 : -1);

    numLoad++
    //console.log(test)

    const sortedBuild = () => {
        var tmpLA = [];
        var tmpAK = [];
        var tmpMerged;

        build.map((e) => {
            if (e.name.includes("LA")) {
                tmpLA.push(e);
            } else if (e.name.includes("AK")) {
                tmpAK.push(e);
            }
        })
        tmpMerged = tmpLA.concat(tmpAK);
        return tmpMerged;
    }

    useEffect(() => {
        if (isFinished.val) {
            var id = 0;
            if (props.model.build.id !== null) {
                props.br.current[props.model.build.id].click();
            }
            if (props.model.lampshade.id !== null) {
                props.lr.current[props.model.lampshade.id].click();
            }
            const interval = setInterval(() => {

                if (id == 0) {
                    props.br.current[product.build.id].click();
                } else if (id == 1) {
                    if (product.lampshade !== null) {
                        props.lr.current[product.lampshade.id].click();
                    }
                } else if (id == 2) {
                    //props.setSideOpened(isFinished.type);
                    props.setOnBoardingV(false);
                    props.setRequired({ ...props.required, lampshade: true, build:true })
                } else {
                    clearInterval(interval);
                }
                id++;
            }, 50);

            return () => clearInterval(interval);
        }
    }, [isFinished.val]);

    // reset on showing page
    useEffect(() => {
        if (props.onBoarding) {
            setIndex(0);
            setProduct({ build: null, lampshade: null });
            setFinished({ val: false, type: "" });
        }
    }, [props.onBoarding])

    const transitions = useTransition(index, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: "translate3d(100%,0,0)" },
        enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
        leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
        config: {
            duration: index == 0 ? 0 : 500,
            easing: easings.easeInOutQuart,
        }
    })

    const obTransition = useTransition(props.onBoarding, {
        from: { opacity: 0, transform: "translate3d(100%,0,0)" },
        enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
        leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
        delay: 0,
        onRest: (_springs, _ctrl, item) => {
            if (isFinished.val) {
                //props.setOnBoarding(false);
                //localStorage.setItem("firstVisit", "false");
            }
        },
        config: {
            duration: 10,
            easing: easings.easeInOutQuart,
        }
    })

    function setStep(stp) {
        setIndex(stp);
    }

    const pages = [
        ({ style }) => (
            <animated.div className="step init" style={{ ...style }}>
                <div className="init">
                    <h1>{t("welcomeOnboarding")}</h1>
                    {/*<p className='text'>{t("onBoardText")}</p>*/}
                    <button className='startButton' onClick={() => setStep(index + 1)}>{t("startExperience")}</button>
                </div>
                {(numLoad >= 4 && !props.isMobile) && <video muted autoPlay loop id="videoBG">
                    <source src="/images/BG_LA.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>}
                <div className="videoLoading"></div>
            </animated.div>
        ),
        ({ style }) => (
            <animated.div className="step first" style={{ ...style }}>
                <h2>{t("selectBuild")}</h2>
                <div className='filter'>
                    <h4>Type</h4>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {
                                props.data.build_categories.map((cat) => {
                                    const n = cat.name_en === 'All builds' ? "" : cat.name_en;
                                    const corrected = cat.name_en === 'All builds' ? { fr: "Tous", en: "All" } : { fr: cat.name_fr, en: cat.name_en }
                                    return <MenuItem
                                        key={cat.id}
                                        value={n}>
                                        {t("customWord", { 'en': corrected.en, "fr": corrected.fr })}
                                    </MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
                <div className='selectProduct'>
                    {
                        sortedBuild().filter(el => el.archived.includes("false")).filter(el => el.category.includes(filter)).map((e) => {
                            return <Btn data={e} product={product} setFinished={setFinished} setProduct={setProduct} setStep={setStep} index={index} key={e.id} type="build" name={e.name} code={e.code} />
                        })
                    }
                </div>
            </animated.div>
        ),
        ({ style }) => (
            <animated.div className="step second" style={{ ...style }}>
                <h2>{t("selectLampshade")}</h2>
                <div className='selectProduct'>
                    {
                        props.data.lampshades.filter(el => el.archived.includes("false")).filter(el => product.build?.lampshades.includes(el.id)).map((e) => {
                            return <Btn data={e} product={product} setFinished={setFinished} setProduct={setProduct} setStep={setStep} index={index} key={e.id} type="lampshade" name={e.name} code={e.code} />
                        })
                    }
                </div>
            </animated.div>
        )

    ]
    useEffect(() => {
        transRef.start()
    }, [index])

    /* ({ style }) => (
        <animated.div className="step" style={{ ...style }}>
            <h2>{t("customColors2")}</h2>
            <div className='bigButtons'>
                <button onClick={() => setFinished({ val: true, type: "palette" })}>{t("seeCollections")}</button>
                <button onClick={() => setFinished({ val: true, type: "individual" })}>{t("chooseMyColors")}</button>
            </div>
        </animated.div>
    ) */

    /*useEffect(() => {
        console.log(product);
    }, [product])*/


    //console.log("reload!!");

    return obTransition(
        (style, item) => item && <animated.div style={style} id="onBoarding">
            <ProgressBar product={product} setStep={setIndex} numSteps={pages.length - 1} currStep={index} />
            {transitions((style, i) => {
                const Page = pages[i]
                return <Page style={style} />
            })}
        </animated.div>
    )
}


function ProgressBar(props) {

    const steps = Array(props.numSteps).fill(0);
    //var deactive = false;
    function setStep(stp) {

        if (stp < props.currStep) {
            if (stp == 2) {
                if (props.product.lampshade !== null) {
                    props.setStep(stp)
                }
            } else {
                props.setStep(stp)
            }
        }

    }
    const isVisible = props.currStep !== 0 ? " barVisible" : ""
    return (
        <div className={"progressBar" + isVisible}>
            <div>
                {steps.map((e, i) => {
                    const isDone = i + 1 < props.currStep ? i === 2 && props.product.lampshade === null ? "disabled" : "done" : "";
                    const curr = i + 1 == props.currStep ? "active" : "";
                    function Span() {
                        var rtn = "";
                        if (i + 1 < steps.length) {
                            rtn = <span className='pbSep'></span>;
                        }
                        return rtn;
                    }
                    return (
                        <React.Fragment key={i}>
                            <div onClick={() => setStep(i + 1)} className={isDone + " pb " + curr}><p>{i + 1}</p></div>
                            <Span />
                        </React.Fragment>
                    )
                })}
            </div>
        </div>
    )
}

function Btn(props) {
    function setNext() {
        if (props.type == "build") {
            if (props.data.lampshades?.length > 0) {
                props.setStep(props.index + 1)
            } else {
                console.log("isMoon");
                props.setFinished({ val: true, type: "" });
                //props.setStep(props.index + 2)
            }
        } else {
            //props.setStep(props.index + 1)
            // console.log("Finish");
            props.setFinished({ val: true, type: "" });
        }
        if (props.type === "build" && props.product.lampshade !== null) {
            props.setProduct({ build: props.data, lampshade: null })
        } else {
            props.setProduct({ ...props.product, [props.type]: props.data })
        }
    }
    const active = props.product[props.type]?.name === props.name ? "active" : "";
    return (
        <button onClick={() => setNext()} className={'btn ' + active}>
            <img alt={props.name} src={"/images/elements/" + props.type + "s/" + props.code + ".png"} />
            <h3>{props.name}</h3>
        </button>
    )
}