import { useTranslation } from "react-i18next";
import { useState } from "react";
import { animated, useTransition, easings } from '@react-spring/web'
import './styles/Popup.css';
import { useForm } from "react-hook-form";
import { Input, Select, MenuItem, TextField, Button, createTheme } from "@material-ui/core";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { colorMaterials, currCanopy, currStem, currVariation } from "./MyFunctions";
import { ThemeProvider } from "@mui/material";
import LightIcon from '@mui/icons-material/Light';
import BuildIcon from '@mui/icons-material/Build';
import axios from "axios";
import { CountryRegionMUISelectors } from "./components/ContryField";
import { CountryRegionData } from "react-country-region-selector";
import { countries } from "./components/Contries";
import i18n from "./i18n";


function Cart(props) {

    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm();
    const { t } = useTranslation()

    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const [country2, setCountry2] = useState("");
    const [region2, setRegion2] = useState("");

    const zeCode = String(props.code.join(''));
    const zeLink = window.location.href.split('?')[0] + "?code=" + zeCode;

    const onSubmit = (data, e) => {
        // console.log(data);
        sendMail(data, closeAndMsg, setRegion, setCountry);
        // e.target.reset(); // reset after form submit
        // reset();
        
        setLoading(true);
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2a2a2a',
                darker: '#2a2a2a'
            }
        }
    });

    const getRegions = country => {
        // console.log(country);
        if (!country) {
            return [];
        }
        return country.regions.map(regionPair => {
            return regionPair[i18n.language];
        });
    };

    //console.log(props.cart);

    const hasVariation = currVariation.data.name !== undefined ? " (" + currVariation.data.name + ")" : "";
    const build = props.cart.build.name !== '' ? props.cart.build.title + hasVariation : t('none');
    const lampshade = props.cart.lampshade.name !== '' ? props.cart.lampshade.title : t('none');


    const cartTransition = useTransition(props.cartVisible, {
        from: { opacity: 0, scale: 1.1 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 1.1 },
        delay: 0,
        config: {
            duration: 250,
            easing: easings.easeInOutQuart,
        }
    })
    const close = (close) => {
        //console.log(close);
        props.setCartVisible(!close);
        reset();
        setLoading(false);
        setRegion('');
        setCountry('');
        //setSelected(props.currentBGid); 
        //props.setCurrentBGid(null);
    };

    const errorText = (val) => {
        var txt = ' ';
        if (val !== undefined) {
            txt = t('requiredField');
        }
        return txt;
    }

    const closeAndMsg = () => {
        close(true);
    }

    const includeUB = currStem.data?.code !== undefined ? currStem.data.code.includes("UB") : false;
    //console.log(currStem.data.code)
    const haveTwoStem = props.currModel.build.data?.lampshadeNumber > 1;

    const stemName = currStem.data?.code !== undefined ? t("customWord", { 'en': currStem.data.name_en, 'fr': currStem.data.name_fr }) : "N/A";
    const canopyName = currCanopy.data?.code !== undefined ? t("customWord", { 'en': currCanopy.data.name_en, 'fr': currCanopy.data.name_fr }) : t("customWord", { 'en': "other", "fr": "autre" });

    function colorByCode(code) {
        var val = null;
        props.data.colors.map((el) => {
            if (el.code === code) {
                val = el;
                return false;
            }
            return false;
        });
        return val;
    }

    function handleChange(type, val) {
        // console.log(val);
        if (type === "country") {
            setCountry(val)
            // if (val.code == "other") {
            //     setRegion(val.regions[0][i18n.language])
            //     setValue('province/state', region);
            // } else {
                setRegion('')
                setValue('province/state', '');
            // }
            // setValue("country",val[0])
        } else {
            setRegion(val)
        }
    }

    return cartTransition(
        (style, item) => item && <animated.div style={{ opacity: style.opacity }} className="popup noprint">
            <div onClick={() => close(true)} className="popup-bg"></div>
            <animated.div style={{ scale: style.scale }} className="popup-prompt">
                <div onClick={() => close(true)} className="popup-quit"></div>
                <div className="popup-prompt-top">
                    <h2>{t("submission")}</h2>
                </div>
                <span className="separator"></span>
                <ThemeProvider theme={theme}>
                    <div className="popup-prompt-parent padding">
                        {/* "handleSubmit" will validate your inputs before invoking "onSubmit" 
                            <button onClick={() => closeAndMsg()}>Reset</button>*/}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" {...register("applink", { value: zeLink })} id="input-applink" />
                            <TextField className="popup-textfield space" helperText={errorText(errors.fullname)} error={errors.fullname !== undefined} sx={{ borderRadius: 0 }} size="small" {...register("fullname", { required: true })} id="input-name" label={t('lab-name') + ' *'} variant="outlined" />
                            <TextField className="popup-textfield" helperText={errorText(errors.email)} error={errors.email !== undefined} sx={{ borderRadius: 0 }} size="small" type='email' {...register("email", { required: true })} id="input-email" label={t('lab-email') + ' *'} variant="outlined" />
                            <TextField className="popup-textfield space" helperText={errorText(errors.phone)} error={errors.phone !== undefined} sx={{ borderRadius: 0 }} size="small" type='phone' {...register("phone", { required: true })} id="input-phone" label={t('lab-phone') + ' *'} variant="outlined" />
                            <TextField className="popup-textfield" helperText=' ' sx={{ borderRadius: 0 }} size="small" id="input-company" {...register("company")} label={t('lab-company')} variant="outlined" />
                            {/* <CountryRegionMUISelectors helperText={errorText(errors.phone)} setCountry={setCountry} country={country} setRegion={setRegion} region={region} sx={{ borderRadius: 0 }} /> */}
                            <TextField
                                id="input-country"
                                label={t("lab-country") + " *"}
                                value={country}
                                className="popup-textfield space"
                                size='small'
                                variant='outlined'
                                select
                                onChange={(val) => handleChange("country", val.target.value)}
                                helperText={errorText(errors.country)}
                                error={errors.country !== undefined}
                                inputProps={register("country", { onChange: setValue("country", country[i18n.language]), required: true })}
                            >
                                {countries.map((option, index) => (
                                    <MenuItem key={option.code} value={option}>
                                        {option[i18n.language]}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {/* <input value={country[0]}  id="input-country2" type="hidden" /> */}
                            <TextField
                                id="input-state"
                                label={t("lab-state") + " *"}
                                value={region}
                                className="popup-textfield"
                                size='small'
                                variant='outlined'
                                select
                                onChange={(val) => handleChange("state", val.target.value)}
                                helperText={errorText(errors["province/state"])}
                                error={errors["province/state"] !== undefined}
                                inputProps={register("province/state", { required: true })}
                            >
                                {getRegions(country).map(
                                    (option, index) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                            <TextField sx={{ borderRadius: 0 }} fullWidth minRows={4} multiline size="small" {...register("message")} id="input-message" label="Message" variant="outlined" />
                            {/* errors will return when field validation fails  */}
                            <div className="popup-prompt-parent-material">
                                <div className="popup-prompt-material">
                                    <div className="mtl">
                                        {/*<BuildIcon />*/}
                                        <div>
                                            <h4>{t('build')}</h4>
                                            <h3>{build}</h3>
                                        </div>
                                        <div>
                                            <h4>{t('canopyType')}</h4>
                                            <h3>{canopyName}</h3>
                                        </div>
                                        <div>
                                            <h4>{t('stemLength')}</h4>
                                            <h3>{stemName}</h3>
                                        </div>
                                    </div>
                                    <div className="mtl">
                                        {
                                            props.cart.build.materials.map((e, i) => {
                                                const num = e.type.replace(/\D/g, '')
                                                const isStem = e.type === "stem" ? includeUB ? haveTwoStem ? true : false : true : true;
                                                const goodColor = e.code === "SAME_EXT" ? colorByCode(colorMaterials.curr.exterior).hex : e.code === "SAME_STEM" ? colorByCode(colorMaterials.curr.stem).hex : e.hex;
                                                //console.log(e.code);
                                                return (
                                                    isStem && <MTL key={i} type={t(e.type.replace(/[0-9]/g, '')) + " " + num} hex={goodColor} color={t("customWord", { en: e.name.en, fr: e.name.fr })} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="popup-prompt-material">
                                    <div className="mtl">
                                        {/*<LightIcon />*/}
                                        <div>
                                            <h4>{t('lampshade')}</h4>
                                            <h3>{lampshade}</h3>
                                        </div>
                                    </div>
                                    <div className="mtl">
                                        {
                                            props.cart.lampshade.materials.map((e, i) => {
                                                const goodColor = e.code === "SAME_EXT" ? colorByCode(colorMaterials.curr.exterior).hex : e.code === "SAME_STEM" ? colorByCode(colorMaterials.curr.stem).hex : e.hex;
                                                const num = e.type.replace(/\D/g, '')
                                                return (
                                                    <MTL key={i} type={t(e.type.replace(/[0-9]/g, '')) + " " + num} hex={goodColor} color={t("customWord", { en: e.name.en, fr: e.name.fr })} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            <LoadingButton className="popup-bottomButton" sx={{ borderRadius: 0 }} loadingPosition="end" endIcon={<SendIcon />} loading={loading} disableElevation fullWidth variant="contained" size='large' type="submit" >{t('submit')}</LoadingButton>
                        </form>
                    </div>
                </ThemeProvider>
            </animated.div>
        </animated.div>
    );
}

function sendMail(data, reset) {
    axios.post(
        "https://mailer.witify.io/api/mail/submit",
        {
            subject: 'Demande de soumission',
            data: data
        },
        {
            headers: {
                Authorization: "Bearer $2y$10$.Dc5bEbcPs2AfwW8kQYIgufLRTpDeLhc1tsWXjyctg.QVf4n3032q"
            }
        }
    ).then(response => {
        console.log(response)
        if (response.status >= 200 && response.status < 300) {
            reset();
        } else {

        }

        // Handle response
    })
}

function MTL(props) {
    return (
        <div>
            <h4>{props.type}</h4>
            <div>
                <h3>{props.color}</h3>
                <span style={{ backgroundColor: props.hex }}></span>
            </div>
        </div>
    )
}

export default Cart;