import { useTranslation } from "react-i18next";
import { Chat } from "./Chat";
import { ZendeskAPI } from "react-zendesk";
import Env3Dside from "./Env3DSide";

function RightOpt(o) {
    const { t } = useTranslation();

    const iframe = document.getElementById('appIframe');

    const closeApp = () => {
        o.setCurrentBGid(null);
        iframe.contentWindow.postMessage({
            integration: { mode: 'normal', name: '' },
        });
    }

    const menuEnv3DOpenend = o.menuEnv3D ? "active" : "";

    const env3Dvisible = () => {
        var rtn = false;
        const bg = o.model?.build.data?.background;
        const dataBG = o.data?.background;
        if (bg) {
            dataBG.forEach(e => {
                //console.log(o.data.background);
                if (bg.includes(e.id)) {
                    rtn = true;
                }
            });
        }
        return rtn;
    }

    const envVisible = env3Dvisible();

    return (
        <div className={menuEnv3DOpenend + (envVisible ? "" : " envInvisible")} id={o.id}>
            <button onClick={() => o.print()} className="export3d"><p>{t("export3d")}</p><span></span></button>
            {/*<button onClick={() => ZendeskAPI('messenger', 'open')} className="chat"><p>{t("chatTo")}</p><span></span></button>*/}
            {o.currentBGid === null && <button onClick={() => o.setEnvVisible(true)} className="env3d mobileMenu"><p>{t("seeEnv3d")}</p><span></span></button>}
            {o.currentBGid !== null && <button onClick={() => closeApp()} className="closeenv3d mobileMenu"><p>{t("closeenv3d")}</p><span></span></button>}
            {envVisible && <Env3Dside
                model={o.model}
                iframe={o.iframe}
                currentBGid={o.currentBGid}
                setCurrentBGid={o.setCurrentBGid}
                data={o.data}
                menuEnv3D={o.menuEnv3D}
                setMenuEnv3D={o.setMenuEnv3D}
            />}
        </div>
    );
}
export default RightOpt;