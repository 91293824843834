import { useTranslation } from "react-i18next";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useState } from "react";

var r = document.querySelector(':root');


function Env3Dside(props) {
    const { t } = useTranslation()
    const [opened, setOpened] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    // setSelected(props.currentBGid); 
    const open3Denv = (id) => {
        if (props.currentBGid !== id) {
            props.setCurrentBGid(id)
            props.iframe.contentWindow.postMessage({
                integration: { mode: 'photo', name: bgById(id) },
            });
        }

    }

    const closeApp = () => {
        props.setCurrentBGid(null);
        //props.setMenuEnv3D(false);
        props.iframe.contentWindow.postMessage({
            integration: { mode: 'normal', name: '' },
        });
    }

    const bgById = (id) => {
        var tmp = null;
        {
            props.data.background.map((bg) => {
                if (bg.id === id) {
                    tmp = bg.code;
                }
            })
        }
        return tmp;
    }

    const isActive = props.menuEnv3D ? " active" : "";
    const isActiveRot = props.menuEnv3D ? 1 : 0;
    const exitButton = props.currentBGid !== null ? " exitBtn" : ""

    r.style.setProperty('--bgValues', props.model.build.data?.background.length);

    return (

        <div className={"noprint env3d-side" + isActive + exitButton}>
            <div onClick={() => props.setMenuEnv3D(!props.menuEnv3D)} className="titles">
                <h2>{t("env3d")}</h2>
                <KeyboardArrowDownRoundedIcon
                    sx={{ "transform": "rotate(" + 180 * isActiveRot + "deg)", "opacity": isActiveRot }}
                    className="animate"
                />
            </div>
            <div className="env3d-parent">
                {/* {props.data?.background.map((bg) => { */}
                {props.data?.background.map((bg) => {
                    return <Button3D
                        model={props.model}
                        selected={props.currentBGid}
                        setSelected={props.setCurrentBGid}
                        iframe={props.iframe}
                        open3Denv={open3Denv}
                        key={bg.id}
                        num={bg.id}
                        setCurrentBGid={props.setCurrentBGid}
                        title={t("customWord", { 'en': bg.name_en, "fr": bg.name_fr })}
                        bg={bg.code}
                    />
                })}
            </div>
            {props.currentBGid !== null && <div className="bottom" onClick={closeApp}>
                <h2>{t("exit3D")}</h2>
            </div>}
        </div>
    );
}

function Button3D(props) {
    const { t } = useTranslation();
    //console.log
    const active = props.selected === props.num ? "active" : "";
    const disabled = props.model.build.data?.background.includes(props.num) ? '' : "disabled";

    const setScene = (number) => {
        if (disabled === '') {
            props.open3Denv(number);
        }

    }

    return (
        <div style={{ display: disabled === 'disabled' ? "none" : "block" }} className={active + ' ' + disabled} onClick={() => setScene(props.num)}>
            <div className="popup-prompt-thumbnail" style={{ backgroundImage: "url(../env3d/" + props.bg + ".jpg" }}></div>
            <h4>{disabled === 'disabled' ? t('unavailable') : props.title}</h4>
        </div>
    )
}

export default Env3Dside;