import { useState, useEffect } from 'react';
import { pdf, PDFDownloadLink, usePDF } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useTranslation } from "react-i18next";
import { colorMaterials, currStem } from './MyFunctions';
import QRCode from 'qrcode'
import { animated, useTransition, easings } from '@react-spring/web'
import './styles/Popup.css';
import LoadingScreen from './LoadingScreen';
import PrintPage2 from './PrintPage2';
import { saveAs } from 'file-saver';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


function PDF(props) {
    const { t } = useTranslation();
    const [val, setVal] = useState({ build: 'none', lampshade: 'none' })
    const [QR, setQR] = useState('./images/logo_noir.png');
    const zeCode = String(props.code.join(''));
    const tmpCode = zeCode.length <= 0 ? ['', '', '', ''] : zeCode.match(/.{1,11}/g);
    const zeLink = window.location.href.split('?')[0] + "?code=" + zeCode;
    const zePDF = <PrintPage2 data={props.data} currModel={props.model} val={val} qr={QR} link={zeLink} code={tmpCode} img={props.sImg} />;
    const [elPDF, updateElPDF] = usePDF({ document: zePDF });

    /*var QR = (<QRImage 
        text={window.location.href+"?code="+props.code}
    />);*/



    useEffect(() => {
        if (props.bImg) {
            setValues()
            QRCode.toDataURL(zeLink, { errorCorrectionLevel: 'H', margin: 0 }, function (err, url) {
                setQR(url);
            })
        }
        //console.log(props.bImg);
    }, [props.bImg])

    useEffect(() => {
        if (val.build !== 'none') {
            //window.print()
            updateElPDF();
        }

        //console.log(sImg);
    }, [val])

    function findColor(code) {
        var c = '';
        props.dataColor.map((color) => {
            if (color.code === code) {
                c = color
            }
            return false
        })
        return c
    }


    function setValues() {
        var build = {
            name: props.currModel.build.title,
            materials: {}
        }
        var lampshade = {
            name: props.currModel.lampshade.title,
            materials: {}
        }
        const includeUB = currStem.data !== null ? currStem.data.code?.includes("UB") : false;
        Object.entries(colorMaterials.curr).forEach(entry => {
            const [key, value] = entry;
            if (props.mtl.includes(key)) {
                if (key.includes('wire') || key.includes('base') || key.includes('hardware') || key.includes('stem') || key.includes('canopy') || key.includes('swivel')) {
                    var toggle2 = "";
                    if (includeUB) {
                        toggle2 = key;
                    } else {
                        if (Object.keys(colorMaterials.curr).includes("stem2") && Object.keys(colorMaterials.curr).includes("stem")) {
                            toggle2 = key;
                        } else {
                            if (key == "stem2") {
                                toggle2 = "stem";
                                console.log("fsafa");
                            } else {
                                toggle2 = key;
                            }
                        }
                    }
                    build.materials[toggle2] = findColor(value);
                } else {
                    var tmp = key;
                    if (key === "glass") {
                        tmp = "glassType"
                    } else if (key === "glassint") {
                        tmp = "glassColor"
                    }
                    lampshade.materials[tmp] = findColor(value);
                }
            }
        });
        setVal({ ...val, build: build, lampshade: lampshade })
    }

    const pdfTransition = useTransition(props.pdfVisible, {
        from: { opacity: 0, scale: 1.1 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 1.1 },
        delay: 0,
        config: {
            duration: 250,
            easing: easings.easeInOutQuart,
        }
    });

    const close = (close, forceClose = false) => {
        const zeClose = forceClose ? false : !close;
        props.setBimg(false);
        props.setPDFVisible(zeClose);
        if (!close) {
            /*if(!props.isProduct){

            }else{*/
            generatePdfDocument();
            //}

        }
    };

    const generatePdfDocument = async (documentData) => {
        pdf((
            zePDF
        )).toBlob().then(blob => saveAs(blob, 'Creation_' + val.build.name.replace(' ', '') + '-' + val.lampshade.name.replace(' ', '') + '.pdf'));
    };



    useEffect(() => {
        if (props.isProduct) {
            if (elPDF.loading && props.pdfVisible) {
                close(false, true);
            }
        }

    }, [elPDF.loading]);

    return pdfTransition(

        (style, item) => item && <animated.div style={{ opacity: style.opacity }} className="popup pdf noprint">
            {props.isProduct &&
                elPDF.loading && <LoadingScreen />
            }
            {!props.isProduct && <div onClick={() => close(true)} className="popup-bg"></div>}
            {!props.isProduct &&
                <animated.div style={{ scale: style.scale }} className="popup-prompt">
                    <div onClick={() => close(true)} className="popup-quit"></div>
                    <div className="popup-prompt-top">
                        <h2>{t("export3d")}</h2>
                    </div>
                    <span className="separator"></span>
                    <div className="popup-prompt-parent">
                        {
                            elPDF.loading ? <LoadingScreen /> :
                                <Document file={elPDF.url} >
                                    <div className='triangle'></div>
                                    <Page pageNumber={1} />
                                </Document>
                        }
                    </div>
                    <div className="popup-prompt-bottom">
                        <button onClick={() => close(true)} className="cancel">{t("cancel")}</button>
                        <button onClick={() => close(false)} className="accept">{t("downloadPDF")}</button>
                    </div>
                </animated.div>
            }
        </animated.div>
    );
}


export default PDF;