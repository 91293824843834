export var colorMaterials = {
    default: {
        "canopy": "SAME_EXT",
        "wire": "TWT.W",
        "hardware": "BRR",
        "swivel": "BRR",
        "stem": "WTM",
        "exterior": "WTM",
        "exterior2": "WTM",
        "exterior3": "WTM",
        "interior": "SAME_EXT",
        "terrazzo": "O.WHT",
        "terrazzobase": "O.WHT",
        "glass": "G_FROSTED",
        "glassint": "GB.O",
        "cable": "CST",
        "hook": "BRR",
        "cablehardware": "BRR",
        "chrome":"CHR.BZG"
    }, curr: {}, prev: {}
};
export var currentBG = { color: "Grey", hex: null };
export var prevBG = { color: "Grey", hex: null };
export var currentPalette = { id: null, code: [] };
export var prevPalette = { id: null, code: [] };

export var currCanopy = { data: {} };
export var prevCanopy = { data: {} };
export var originalCanopy = { data: {} };

export var currStem = { data: {} };
export var originalStem = { data: {} };
export var prevStem = { data: {} };

export var currVariation = { data: {} };
export var originalVariation = { data: {} };
export var prevVariation = { data: {} };

export const sortOrder = [
    'exterior',
    'exterior2',
    'exterior3',
    'chrome',
    'interior',
    'glass',
    'glassint',
    'terrazzo',
    'canopy',
    'stem',
    'hardware',
    'cable',
    'cablehardware',
    'hook',
    'swivel',
    'terrazzobase',
    'wire',
]
export const sortOrderProduct = [
    'canopy',
    'hardware',
    'cable',
    'cablehardware',
    'hook',
    'swivel',
    'stem',
    'terrazzobase',
    'wire',
    'exterior',
    'exterior2',
    'exterior3',
    'chrome',
    'interior',
    'glass',
    'glassint',
    'terrazzo',
]

export const bgValues = [
    {
        'islandH': "3",
        'islandW': "10",
        'ceilingH': "9",
        'type':'island'
    },
    {
        'islandH': "2¾",
        'islandW': "6¾",
        'ceilingH': "9½",
        'type':'table'
    },
    {
        'islandH': "3",
        'islandW': "12",
        'ceilingH': "9",
        'type':'island'
    },
    {
        'islandH': "3",
        'islandW': "12",
        'ceilingH': "11",
        'type':'heightOnly'
    },
    {
        'islandH': "3",
        'islandW': "12",
        'ceilingH': "13",
        'type':'heightOnly'
    }
]

export var productID = { build: null, lampshade: null };

export function lightOrDark(color) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    }
    else {

        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 245) {

        return 'Light';
    }
    else {

        return 'Dark';
    }
}