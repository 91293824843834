import './styles/Possibilites.css';

export default function Possibilities(props) {

    console.log(props.data.builds)
    function isZero(num) {
        var rtn = num;
        if (num == 0) {
            rtn = 1;
        }
        return rtn;
    }
    var grandTotal1 = 0;
    var grandTotal2 = 0;

    function findLampshade(id) {
        var rtn = null;
        props.data.lampshades.some((el) => {
            if (el.id == id) {
                rtn = el;
                return true
            }
            return false
        })
        return rtn;
    }
    return (
        <div id="report">
            <table>
                <thead>
                    <tr>
                        <th>
                            Build name
                        </th>
                        <th>
                            Num of lampshades
                        </th>
                        <th>
                            Num of canopies
                        </th>
                        <th>
                            Num of stems
                        </th>
                        <th>
                            Num of options
                        </th>
                        <th>
                            Sub total w/o materials
                        </th>
                        <th>
                            Sub total w/ materials
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.builds.map((el, i) => {
                        var tmpOpt = 0;
                        el.modeloptions.map((el2) => {
                            tmpOpt += el2.lampshades.length
                        })

                        const lamOpt = el.modeloptions.length <= 0 ? isZero(el.lampshades.length) : tmpOpt;
                        const multiplicator = isZero(el.canopy.length) * isZero(el.stem.length)
                        const subTotalNoMtl = lamOpt * multiplicator;

                        // MATERIALS //
                        var subTotalMtl = 0;

                        if (el.modeloptions.length <= 0) {
                            var tmpBTotal = 0;
                            var tmpBs = 1;
                            Object.values(el.materials).map((mtlB) => {
                                if (mtlB.length > 0) {
                                    tmpBs *= mtlB.length;
                                }
                            })
                            tmpBTotal += tmpBs;

                            var tmpLsTotal = 0;
                            el.lampshades.map((lamp) => {
                                const ls = findLampshade(lamp);
                                if (ls.archived === "false") {
                                    var tmpLs = 1;
                                    Object.values(ls.materials).map((mtlL) => {
                                        //console.log(mtl.length);
                                        if (mtlL.length > 0) {
                                            tmpLs *= mtlL.length;
                                        }
                                    })
                                    tmpLsTotal += tmpLs
                                    //console.log(el.name, ls.name, tmpBTotal);
                                    subTotalMtl += multiplicator * (isZero(tmpLsTotal) * isZero(tmpBTotal));
                                    //console.log(el.name,multiplicator * (isZero(tmpLsTotal) * isZero(tmpBTotal)));
                                }

                            })
                            if (el.lampshades.length <= 0) {
                                subTotalMtl += multiplicator * (isZero(tmpLsTotal) * isZero(tmpBTotal));
                            }
                        } else {
                            el.modeloptions.map((el2) => {
                                var tmpBTotal = 0;
                                var tmpBs = 1;
                                Object.entries(el.materials).map(([mtlBname,mtlB]) => {
                                    if (mtlB.length > 0) {
                                        //tmpBs *= mtlB.length;
                                        if(mtlBname === "cable" && el2.name === "LA00-LOOP"){ // LA00-LOOP
                                            //
                                        }else{
                                            tmpBs *= mtlB.length;
                                        }
                                    }
                                })
                                var tmpLsTotal = 0;
                                tmpBTotal += tmpBs;
                                el2.lampshades.map((lamp) => {
                                    const ls = findLampshade(lamp);
                                    if (ls.archived === "false") {
                                        var tmpLs = 1;
                                        Object.values(ls.materials).map((mtlL) => {
                                            //console.log(mtl.length);
                                            if (mtlL.length > 0) {
                                                
                                                tmpLs *= mtlL.length;
                                            }
                                        })
                                        tmpLsTotal += tmpLs
                                        //console.log(el.name, ls.name, tmpBTotal);
                                        subTotalMtl += multiplicator * (isZero(tmpLsTotal) * isZero(tmpBTotal));
                                        //console.log(el.name,multiplicator * (isZero(tmpLsTotal)));
                                    }
                                    //console.log(ls2);
                                })
                            })
                        }
                        //console.log(ls)
                        /**/

                        const archived = el.archived !== "false" ? "archived" : "";
                        grandTotal1 += el.archived === "false" ? subTotalNoMtl : 0;
                        grandTotal2 += el.archived === "false" ? subTotalMtl : 0;
                        return (
                            <tr className={archived} key={i}>
                                <td>{el.name}</td>
                                <td>{isZero(el.lampshades.length)}</td>
                                <td>{isZero(el.canopy.length)}</td>
                                <td>{isZero(el.stem.length)}</td>
                                <td>{isZero(el.modeloptions.length)}</td>
                                <td>{subTotalNoMtl}</td>
                                <td>{subTotalMtl.toLocaleString("fr-CA")}</td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td style={{"textAlign":"right"}} colSpan="5"> TOTAL</td>
                        <td >
                            {grandTotal1.toLocaleString("fr-CA")}
                        </td>
                        <td >
                            {grandTotal2.toLocaleString("fr-CA")}
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}