import React from 'react';
import { useTranslation } from "react-i18next";
import { colorMaterials, currCanopy, currStem, currVariation } from './MyFunctions';
import { Page, Text, View, Document, StyleSheet, Image, Link } from '@react-pdf/renderer';
import { PrintStyle } from './styles/Print2';

function PrintPage2(props) {
    const { t } = useTranslation();
    const style = StyleSheet.create(PrintStyle);

    let today = new Date();
    let fullDate = today.toLocaleString();

    function Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    // Separate Build unique combination (ex: LA99 MOON -> build: LA99 | lamshade: MOON)
    const b = props.val.build.name !== undefined ? props.val.build.name.includes(' ') : false;
    //console.log(b);
    const hasVariation = currVariation.data.name !== undefined ? " (" + currVariation.data.name + ")" : ""
    //console.log(props.val)
    const correctedL = b ? props.val.build.name.split(' ')[1] : props.val.lampshade.name;
    const correctedB = b ? props.val.build.name.split(' ')[0] : props.val.build.name + hasVariation;

    //console.log(currStem.data.code);
    const includeUB = currStem.data?.code !== undefined ? currStem.data.code.includes("UB") : false;
    const haveTwoStem = props.currModel.build.data?.lampshadeNumber > 1;

    const fileL = props.currModel.lampshade.data ? props.currModel.lampshade.data.file3d !== 'none' ? props.currModel.lampshade.data.file3d : "" : "";
    const fileB = props.currModel.build.data ? props.currModel.build.data.file3d !== 'none' ? props.currModel.build.data.file3d : "" : "";
    //console.log(fileL, fileB);

    function colorByCode(code) {
        var val = null;
        props.data.colors.map((el) => {
            if (el.code === code) {
                val = el;
                return false;
            }
            return false;
        });
        return val;
    }

    return (
        <Document producer='Propulsé par Edge Dimension' creator='Luminaire Authentik - Edge Dimension' title='LA - Creation' style={style.doc} onContextMenu={(e) => e.preventDefault()} >
            <Page dpi='100' orientation='landscape' size="LETTER">
                <View>
                    <View style={style.flexCenter}>
                        <Image style={style.img} src={props.img} />
                    </View>
                    <View style={style.topTxt}>
                        {/* <Text style={[style.topP, style.fontNormal]}>USAGE INTÉRIEUR SEULEMENT, INDOOR USE ONLY</Text> */}
                        <Text style={[style.topP, style.fontNormal]}>UL # Certificat: 20150318-E475067</Text>
                        <Text style={[style.topP, style.fontNormal]}>514.662.0352 | LUMINAIREAUTHENTIK.COM</Text>
                        <Text style={[style.topP, style.fontNormal]}>All rights reserved to Authentik Lighting / Tous les droits sont réservés à Luminaire Authentik © {today.getFullYear()}</Text>
                    </View>
                    <View style={style.bottom}>
                        <View style={style.first}>
                            <Image style={style.logo} src='./images/logo_noir.png' />
                            <View>
                                <Text style={[style.fontNormal, { fontSize: '16pt', fontWeight: '800' }]}>Luminaire Authentik Inc.</Text>
                                <Text style={[style.fontNormal, { fontSize: '8pt', fontWeight: '200' }]}>A: 1122 Rue du S, Cowansville,</Text>
                                <Text style={[style.fontNormal, { fontSize: '8pt', fontWeight: '200' }]}>QC J2K 2Y3</Text>
                                <Text style={[style.fontNormal, { fontSize: '8pt', fontWeight: '200' }]}>T: 514-662-0352 poste 1</Text>
                                <Text style={[style.fontNormal, { fontSize: '12pt', fontWeight: '500' }]} src='mailto:help@luminaireauthentik.com'>help@luminaireauthentik.com</Text>
                                <View style={{ marginTop: '25px', flexDirection: 'row' }}>
                                    <Text style={{ fontSize: '8pt', fontWeight: '500' }}>Made with</Text>
                                    <Image style={{ margin: '0 3px', height: '17', top: '-4' }} src='./images/love_icon.png' />
                                    <Text style={{ fontSize: '8pt', fontWeight: '500' }}>in Cowansville, Québec</Text>
                                </View>
                            </View>
                        </View>
                        <View style={style.second}>
                            <View>
                                <MTL model3D={fileL} style={style} colorText={props.val.lampshade !== 'none' && correctedL || t('none')} title={t("lampshade")} haveColor={false} />
                                {props.val.lampshade !== 'none' && Object.keys(props.val.lampshade.materials).map((e, i) => {
                                    //console.log(props.val.lampshade.materials[e]);
                                    const num = e.replace(/\D/g, '')
                                    const c = props.val.lampshade.materials[e];
                                    const goodColor = c.code === "SAME_EXT" ? colorByCode(colorMaterials.curr.exterior).hex : c.code === "SAME_STEM" ? colorByCode(colorMaterials.curr.stem).hex : c.hex;
                                    return (
                                        <MTL model3D="" key={i} style={style} colorText={t("customWord", { 'en': props.val.lampshade.materials[e].name_en, "fr": props.val.lampshade.materials[e].name_fr })} title={t(e.replace(/[0-9]/g, '')) + " " + num} color={goodColor} haveColor={true} />
                                    )

                                })}
                            </View>
                            <View>
                                <MTL model3D={fileB} style={style} colorText={props.val.build !== 'none' && correctedB || t('none')} title={t("build")} haveColor={false} />
                                <MTL model3D="" style={style} colorText={currCanopy.data !== null && t("customWord", { 'en': currCanopy.data.name_en, 'fr': currCanopy.data.name_fr }) || t("customWord", { 'en': "other", "fr": "autre" })} title={t("canopyType")} haveColor={false} />
                                <MTL model3D="" style={style} colorText={currStem.data !== null && t("customWord", { 'en': currStem.data.name_en, 'fr': currStem.data.name_fr }) || "N/A"} title={t("stemLength")} haveColor={false} />
                                {props.val.build !== 'none' && Object.keys(props.val.build.materials).map((e, i) => {
                                    const isStem = e === "stem" ? includeUB ? haveTwoStem ? true : false : true : true;
                                    const num = e.replace(/\D/g, '')
                                    const c = props.val.build.materials[e];
                                    const goodColor = c.code === "SAME_EXT" ? colorByCode(colorMaterials.curr.exterior ? colorMaterials.curr.exterior : colorMaterials.default.exterior).hex : c.code === "SAME_STEM" ? colorByCode(colorMaterials.curr.stem ? colorMaterials.curr.stem : colorMaterials.default.stem).hex : c.hex;
                                    return (
                                        isStem && <MTL model3D="" key={i} style={style} colorText={t("customWord", { 'en': props.val.build.materials[e].name_en, "fr": props.val.build.materials[e].name_fr })} title={t(e.replace(/[0-9]/g, '')) + " " + num} color={goodColor} haveColor={true} />
                                    )

                                })}
                            </View>

                        </View>
                        <View style={style.third}>
                            <View>
                                <View>
                                    <Text style={[style.fontNormal, { fontSize: '10pt', textTransform: 'uppercase' }]}>{t('productCode')}</Text>
                                    <Link target='_blank' style={[style.fontMono, { width: '150px', textTransform: 'uppercase', color: 'black', textDecoration: 'none' }]} src={props.link}>
                                        <Text>{props.code[0]}</Text>
                                    </Link>
                                    <Link target='_blank' style={[style.fontMono, { width: '150px', textTransform: 'uppercase', color: 'black', textDecoration: 'none' }]} src={props.link}>
                                        <Text>{props.code[1]}</Text>
                                    </Link>
                                    <Link target='_blank' style={[style.fontMono, { width: '150px', textTransform: 'uppercase', color: 'black', textDecoration: 'none' }]} src={props.link}>
                                        <Text>{props.code[2]}</Text>
                                    </Link>
                                    <Link target='_blank' style={[style.fontMono, { width: '150px', textTransform: 'uppercase', color: 'black', textDecoration: 'none' }]} src={props.link}>
                                        <Text>{props.code[3]}</Text>
                                    </Link>
                                </View>
                                <View style={{ marginTop: '25' }}>
                                    <Text style={[style.fontNormal, { fontSize: '8pt' }]}>{Capitalize(t('createdOn'))}</Text>
                                    <Text style={[style.fontNormal, { fontSize: '10pt' }]}>{fullDate}</Text>
                                    <Link target='_blank' src={props.link} style={[style.fontNormal, { color: "black", fontSize: '8pt', marginTop: "10px", textDecoration: "underline" }]}>
                                        <Text >{t("appLink")}</Text>
                                    </Link>
                                </View>
                            </View>
                            <Link target='_blank' src={props.link}>
                                <Image style={{ height: '185', width: '185', right: 0 }} src={props.qr} />
                            </Link>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default PrintPage2;

function MTL(props) {
    const { t } = useTranslation();
    const l = props.model3D !== "";
    return (
        <View style={props.style.mtl}>
            <Text style={[props.style.fontNormal, { fontSize: '8pt', textTransform: 'uppercase', marginBottom: '-1px' }]}>{props.title}</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Text style={[props.style.fontNormal, { fontSize: '11pt', textTransform: 'capitalize', fontWeight: 'bold', maxWidth: props.haveColor ? "90px" : l ? "60px" : "120px", lineHeight: "1" }]}>{props.colorText}</Text>
                {props.haveColor && <View style={[props.style.roundColor, { backgroundColor: props.color }]}></View>}
                {props.model3D !== "" &&
                    <Link target='_blank' style={[props.style.model3D, { flexDirection: 'row', alignItems: 'center', justifyContent: 'right' }]} src={props.model3D}>
                        <Image style={{ height: '10', width: '10', marginRight: "3", display: "block" }} src="./images/model3D.png" />
                        <Text style={[props.style.fontNormal, { fontSize: '7pt', textTransform: 'uppercase', display: "block" }]} >{t("model3D")}</Text>
                    </Link>
                }
            </View>
        </View>
    )
}