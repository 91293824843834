import './styles/TopBar.css';
import i18n from "./i18n";
import { useTranslation } from "react-i18next";
import RightOpt from './RightOpt';
import { Squash as Hamburger } from 'hamburger-react'
import { useState } from 'react';

function TopBar(o) {
    const { t } = useTranslation();

    const [isOpen, setOpen] = useState(false);

    const webLang = t('lang') === "en" ? "fr" : "en";
    const ourServices = t('lang') === "en" ? "nos-services" : "en/our-services";
    function changeLocale(l) {
        i18n.changeLanguage(l);        
    }
    const menuClosed = isOpen ? '' : 'hideMobile';
    const helpActive = o.onBoarding ? "active" : "";

    const zeWebsite = o.urlShort+"?lang="+webLang;
    return (
        <div className="noprint" id="topbar">
            <a id="logo" href={zeWebsite}><img alt="logo" src="/images/logo.png" /></a>
            <h1>{t('title')}</h1>
            <Hamburger distance="lg" size={20} color='white' toggled={isOpen} toggle={setOpen} />
            <div className={menuClosed} id="side">
                <RightOpt setCurrentBGid={o.setCurrentBGid} currentBGid={o.currentBGid} setEnvVisible={o.setEnvVisible} id='rightOptionsMobile' print={o.print} />
                <a target="_blank" href="https://3dwarehouse.sketchup.com/by/luminaireauthentik?tab=collections">{t("download3D")}</a>
                {/* <a className={helpActive} onClick={() => o.setOnBoardingV(!o.onBoarding)}>{t("help")}</a> */}
                <a target='_blank' href={"https://luminaireauthentik.com/" + ourServices}>{t('bookConsultation')}</a>
                <a target='_blank' className="uppercase" onClick={() => changeLocale(t('lang'))}>{t('lang')}</a>
            </div>
            {isOpen && <div onClick={() => setOpen(!isOpen)} id='topBG'></div>}
        </div>
    );
}
export default TopBar;