import { useRef } from "react";
import { useTranslation } from "react-i18next";
var tmp = 0;
function LampEl({ type, parentModel, currModel, el, filtered, loadScreen, setLoadScreen, zeref, isRequired, setRequired, setResetAll }) {
    const { t } = useTranslation()

    /* if(tmp === 7 || tmp === 8){
         if(initID === el.id){
             //toggle();            
             //console.log(el);
             toggle()
             tmp++;
         }
     }else if(tmp<7){
         tmp++;
     }*/


    const myRef = useRef(null);
    function toggle() {
        if (currModel[type].id === el.id) {
            parentModel({ ...currModel, [type]: { model: "", title: "", id: null, data: null } });
            setRequired({ ...isRequired, [type]: false });
        } else {
            setResetAll(true);
            parentModel({ ...currModel, [type]: { model: el.code, title: el.name, id: el.id, data: el } });
            if (el.lampshades?.length > 0) {
                setRequired({ ...isRequired, [type]: true });
            } else {
                setRequired({ ...isRequired, build: true, lampshade: true });
            }
            //myRef.current.scrollIntoView({ block: 'center',  behavior: 'smooth' });
            //setCode();
            if (filtered !== "filtered" && currModel.build.id !== null) {
                setLoadScreen(true);
            }

            //setModel(model);
        }

    }
    function openPDF() {
        window.open("./pdf/" + type + "s/" + el.code + ".pdf", "_blank");
    }
    function open3D() {
        window.open(el.file3d, "_blank");
    }

    var isActive = currModel[type].id === el.id ? "active" : "";
    //var filtered = currModel.model !== undefined;//el.lampshades.find(currModel.id)
    //var filtered = currModelOBJ.includes(el.id)?"filtered":"";
    // console.log(currModelOBJ);
    return (
        <div ref={myRef} data-type={type} data-code={el.code} className={"lamp-element " + isActive + " " + filtered}>
            <button ref={zeref} onClick={toggle} className="lamp-element-content ">
                <img alt={el.name} src={"/images/elements/" + type + "s/" + el.code + ".png"} />
                <h2 className="name">{el.name}</h2>
            </button>
            <div className="actions">
                {el.file3d && el.file3d !== 'none' && <span onClick={() => open3D()} className="btn file">
                    <span className="label">{t("3dFile")}</span>
                </span>}
                {el.pdf === "true" && <span onClick={() => openPDF()} className="btn pdf">
                    <span className="label">{t("abrPDF")}</span>
                </span>}
            </div>
        </div>
    )
}
export default LampEl;