import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

if (!localStorage.getItem('i18nextLng')) {
    localStorage.setItem('i18nextLng', 'fr');
}
//console.log(localStorage.getItem('i18nextLng'))
i18n
    .use(Backend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'fr',
        //lng: 'fr', // default language
        debug: false,

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;