import { useTranslation } from "react-i18next";
import { colorMaterials, currentPalette } from "./MyFunctions";
import LightRoundedIcon from '@mui/icons-material/LightRounded';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import "./styles/OptionsTop.css"
import { useState } from "react";
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

function OptionTop({ closeSideOpt, openSide, currModel, parentModel, canShow, iframe, opt, isRequired, setRequired, preSubmit, forceUpdate, setForceUpdate, currentBGid, restBtnRef }) {
    const { t } = useTranslation();
    const rstBtn = document.getElementById("reset");
    const rstPrompt = document.getElementById("confirmReset");
    function reset() {
        rstBtn.style.display = "none";
        //colorMaterials.curr = colorMaterials.default;
        //colorMaterials.prev = colorMaterials.default;
        Object.assign(colorMaterials.curr, colorMaterials.default);
        Object.assign(colorMaterials.prev, colorMaterials.default);
        currentPalette.id = null;
        currentPalette.code = [];
        setForceUpdate(!forceUpdate);
        showReset(false);
        iframe.contentWindow.postMessage({
            resetMaterials: "",
        });

    }
    function showReset(toShow) {
        if (toShow) {
            closeSideOpt("", true);
        }
        //const s = toShow ? "block" : "none";
        //rstPrompt.style.display = s;
        if(toShow){
            rstPrompt.classList.add("rstOn");
        }else{
            rstPrompt.classList.remove("rstOn");
        }
    }

    function openSideOrClose(type) {
        openSide(type);
        closeSideOpt(type);
        showReset(false);
        if (type === "options") {
            setRequired({ ...isRequired, [type]: true });
        }
    }
    const activeBG = opt === "choose" ? "active" : "";
    const activePalette = opt === "palette" ? "active" : "";
    const activeMtl = opt === "individual" ? "active" : "";

    const reqOpt = isRequired.options || !preSubmit ? "" : "required";

    const [light, setLight] = useState(false);

    function setLights() {
        const iframe = document.getElementById('appIframe');
        setLight(!light)
        iframe.contentWindow.postMessage({
            integration: { light: light },
        });
    }
    return (
        <div className={canShow} id="optiontop">
            <div id='selectionBar' className="col">
                <h4>{t("selection")}</h4>
                <div className="flexButtons">
                    <SmallButton isRequired={isRequired} setRequired={setRequired} close={closeSideOpt} currModel={currModel} parentModel={parentModel} type="build" />
                    <SmallButton isRequired={isRequired} setRequired={setRequired} close={closeSideOpt} currModel={currModel} parentModel={parentModel} type="lampshade" />
                </div>
            </div>
            <span className="separator"></span>
            <div className={"col " + reqOpt}>
                <h4>{t("customizeCreation")}</h4>
                <div onClick={() => openSideOrClose("options")} className={"topBtn " + activeBG} id="chooseBG-top">
                    <LightRoundedIcon />
                    <p>{t("seeDispo")}</p>
                </div>
            </div>
            {/* <span className="separator"></span>
            <div className="col">
                <div id="customColors">
                    <h4>{t("customColors")}</h4>
                    <span className="icon info">
                        <div id="infoCustomColor">{t("paletteOrColor")}</div>
                    </span>
                </div>
                <div onClick={() => openSideOrClose("individual")} className={"topBtn " + activeMtl} id="individual-top">
                    <span className="icon"></span>
                    <p>{t("individual")}</p>
                </div>
                <div onClick={() => openSideOrClose("palette")} className={"topBtn " + activePalette} id="palette-top">
                    <span className="icon"></span>
                    <p>{t("palette")}</p>
                </div> 
            </div>*/}
            <div className="col reset">
                <h4 onClick={() => showReset(true)} id="reset">{t("reset")}</h4>
                <div id="confirmReset">
                    <span onClick={() => showReset(false)}></span>
                    <span></span>
                    <span ref={restBtnRef} onClick={() => reset()}></span>
                </div>
            </div>
            {/*currentBGid !== null && <>               /// Open / close lights
                <span className="separator"></span>
                <div className="col">
                    <div id="showLight">
                        <h4>{t("showLight")}</h4>
                    </div>
                    <div onClick={() => setLights()} className={"topBtn " + activeMtl} id="light-top">
                        {light ? <LightbulbIcon /> : <LightbulbOutlinedIcon />}
                        <p>{t(light ? "turnOff" : "turnOn")}</p>
                    </div>
                </div>
            </>*/}
        </div>
    );
}

function SmallButton({ type, currModel, parentModel, close, isRequired, setRequired }) {
    var deleted = currModel[type].model !== "" ? "" : "deleted";
    function removeSel() {
        parentModel({ ...currModel, [type]: { model: "", title: "", id: null, data: null } })
        setRequired({ ...isRequired, [type]: false });
        close(null, true);
    }
    return (
        <span data-type={type} className={"selBtn " + deleted}><p>{currModel[type].title}</p><span className="icon" onClick={removeSel}></span></span>
    )
}
export default OptionTop;