import { useTranslation } from "react-i18next";
import { colorMaterials, currCanopy, currStem, currVariation } from "./MyFunctions";
import "./styles/BottomBar.css"
import ReceiptIcon from '@mui/icons-material/Receipt';
import i18n from "./i18n";

function BottomBar({ data, currModel, filtered, mtl, cart, setCart, setCartVisible, makeCode, isRequired, setPreSubmit }) {
    const { t } = useTranslation();


    const mutiplier = () => {
        var r = 1;
        if (currModel.build.id !== null) {
            data.builds.map((m) => {
                if (m.id === currModel.build.id) {
                    r = m.lampshadeNumber;
                    return false;
                }
                return false
            })
        }
        return r;
    }
    const priceA = () => {
        var r = { price: 0, valid: false };
        if (currModel.build.id !== null) {
            data.builds.map((b) => {
                if (b.id === currModel.build.id && filtered.build.includes(b.id)) {
                    r.price = b.price;
                    r.valid = true
                    return false;
                }
                return false
            })
        }
        return r;
    }
    const priceB = () => {
        var r = { price: 0, valid: false };
        if (currModel.lampshade.id !== null) {
            data.lampshades.map((l) => {
                if (l.id === currModel.lampshade.id && filtered.lampshade.includes(l.id)) {
                    r.price = l.price
                    r.valid = true
                    return false;
                }
                return false
            })
        }
        return r;
    }

    const priceSupplement = () => {
        var r = 0;

        // ------------------------------------------------- VARIATION
        var varPrice = currVariation.data?.price ? currVariation.data.price : 0;
        //console.log(varPrice)
        //varPrice = currVariation.data?.price

        // ------------------------------------------------- STEM
        var stemPrice = currStem.data?.price ? currStem.data.price : 0;
        // Override price by build        
        if (currStem.data?.priceByBuild) {
            currStem.data.priceByBuild.map(function (e) {
                if (e.buildID === currModel.build.id) {
                    stemPrice = e.price
                }
            })
        }

        // ----------------------------------------------- CANOPY
        var canopyPrice = currCanopy.data?.price ? currCanopy.data.price : 0;

        if (currCanopy.data) {
            //console.log(colorMaterials.curr.canopy);
            if (colorMaterials.curr.canopy !== undefined) {
                //console.log(currCanopy.data,findColor(colorMaterials.curr.canopy).id);
                const mtID = findColor(colorMaterials.curr.canopy).id;
                currCanopy.data.mtlsPrice.map(function (e) {
                    if (e.colorID === mtID) {
                        canopyPrice += e.price;
                    }
                })
            }
        }
        // ---------------------------------------------- CATEGORIES
        var suppCat = 0;
        data.color_categories.map(function (e) {
            //console.log(mtl)
            if (mtl.includes(e.name_en)) {
                if (e.price !== 0) {
                    if (colorMaterials.curr[e.name_en]) {
                        e.exceptions.map(function (exc) {
                            const mtls = findColor(colorMaterials.curr[e.name_en]);
                            if (mtls.id !== exc) {
                                if (!(e.name_en === "interior" && mtl.code === colorMaterials.curr.exterior)) {
                                    if (colorMaterials.curr.exterior === colorMaterials.curr.interior) {
                                        suppCat = 0;
                                    } else {
                                        suppCat = e.price;
                                    }
                                }
                            }
                        });
                    }
                }
            }
        });
        // ---------------------------------------------- COATING
        // -- Build -- //
        var metalBuild = 0;
        if (currModel.build.data) {
            currModel.build.data.mtlsPrice.map((e) => {
                if (e.colorID === findColor(colorMaterials.curr[e.type]).id) {
                    metalBuild += e.price;
                }
            })
        }
        // -- Lampshade -- //
        var metalLampshade = 0;
        if (currModel.lampshade.data) {
            currModel.lampshade.data.mtlsPrice.map((e) => {
                if (e.colorID === findColor(colorMaterials.curr[e.type]).id) {
                    metalLampshade += e.price;
                }
            })
        }
        // ----------------------------------- INDIVIDUAL MATERIAL
        var mtlIndividual = 0;
        Object.entries(colorMaterials.curr).map(entry => {
            const [key, value] = entry;
            if (key === "interior") {
                //console.log(colorMaterials.curr.exterior!==colorMaterials.curr.interior);
                if (colorMaterials.curr.exterior !== colorMaterials.curr.interior) {
                    mtlIndividual += (findColor(value).price)
                }
            } else {
                mtlIndividual += (findColor(value).price)
                //console.log(value);
            }

        })

        return varPrice + stemPrice + canopyPrice + suppCat + ((metalBuild + metalLampshade + mtlIndividual) * mutiplier());
    }

    function findColor(code) {
        var c = '';
        data.colors.map((color) => {
            if (color.code === code) {
                c = color
            }
            return false
        })
        return c;
    }

    function addToCart() {
        const req = Object.values(isRequired);
        if (!req.includes(false)) {
            setPreSubmit(false);
            setCartVisible(true);
            makeCode();
            var build = {
                name: currModel.build.model,
                title: currModel.build.title,
                price: priceA().price,
                numOfHead: mutiplier(),
                materials: []
            }
            var lampshade = {
                name: currModel.lampshade.model,
                title: currModel.lampshade.title,
                price: priceB().price,
                materials: []
            }
            Object.entries(colorMaterials.curr).forEach(entry => {
                const [key, value] = entry;
                if (mtl.includes(key)) {
                    if (key.includes('wire') || key.includes('base') || key.includes('hardware') || key.includes('stem') || key.includes('canopy')) {
                        const color = findColor(value);
                        build.materials.push({ type: key, code: value, hex: color.hex, name: { en: color.name_en, fr: color.name_fr } });
                    } else {
                        var tmp = key;
                        const color = findColor(value);
                        if (key === "glass") {
                            tmp = "glassType"
                        } else if (key === "glassint") {
                            tmp = "glassColor"
                        }
                        lampshade.materials.push({ type: tmp, code: value, hex: color.hex, name: { en: color.name_en, fr: color.name_fr } });
                    }
                }
            });

            //console.log({build:build,lampshade:lampshade});
            setCart({ ...cart, build: build, lampshade: lampshade })
        } else {
            setPreSubmit(true);
        }
    }

    const supp = priceSupplement();
    //console.log(priceA(),priceB());onClick={() => addToCart()}

    const sampleKit = i18n.language == "en" ? "https://www.luminaireauthentik.com/en/shop/collection-72h-en/sample-kit/" : "https://www.luminaireauthentik.com/boutique/collection-72h/trousse-dechantillons/";
    return (
        <div id="bottombar">
            <div id='price'>
                <h5>{t("priceApprox")}</h5>
                {supp > 0 && <h6>{"Option: " + supp + ".00$"}</h6>}
                <p id="totalPrice">{priceA().price + priceB().price * mutiplier() + supp + ".00$"}</p>
                {((priceA().price === 0 && priceA().valid) || (priceB().price === 0 && priceB().valid)) && <div id="notGoodPrice">{t('notPrice')}</div>}
            </div>
            <div className="bottomIcon" onClick={() => addToCart()} id="addtocart">
                <ReceiptIcon />
                <p >{t("submission")}</p>
            </div>
            <div className="bottomIcon" onClick={() => window.open(sampleKit, "_blank")} id="samplekit">
                <span className="icon"></span>
                <p >{t("sampleKit")}</p>
            </div>
        </div>
    );
}
export default BottomBar;