import './styles/LoadingScreen.css'

function LoadingScreen(props){
    return(
        <div className={"loadingScreen noprint "+props.className}>
            <div className='spinner'></div>
        </div>
    ) 
}

export default LoadingScreen;